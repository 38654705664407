// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-design-js": () => import("./../../../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-outdoor-js": () => import("./../../../src/pages/outdoor.js" /* webpackChunkName: "component---src-pages-outdoor-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-templates-design-js": () => import("./../../../src/templates/design.js" /* webpackChunkName: "component---src-templates-design-js" */),
  "component---src-templates-interior-js": () => import("./../../../src/templates/interior.js" /* webpackChunkName: "component---src-templates-interior-js" */),
  "component---src-templates-mobile-js": () => import("./../../../src/templates/mobile.js" /* webpackChunkName: "component---src-templates-mobile-js" */),
  "component---src-templates-outdoor-js": () => import("./../../../src/templates/outdoor.js" /* webpackChunkName: "component---src-templates-outdoor-js" */)
}

