module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-yandex-metrika/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":76373347,"webvisor":true,"trackHash":true,"afterBody":true,"defer":false,"useCDN":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Наружная реклама в Красноярске | RealElement","short_name":"RealElement","description":"Изготовление наружной рекламы в Красноярске: вывески, световые короба, информационные стенды, архитектурные макеты, сувенирная продукция, работы с 3D, дизайн баннеров, визиток и флаеров!","start_url":"/","background_color":"white","theme_color":"white","display":"standalone","icon":"src/images/logo/logo512.png","lang":"ru","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c9f1f377c2f1c069eab21d0fd05ecbed"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
